import './App.css';
import { useState } from 'react';

function Modal({ show, message, emoji, onClose }) {
  if (!show) {
    return null;
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <p className="text-gray-700 text-sm font-bold mb-2">{message}</p>
          </div>
          <div className="mb-4">
            <p className="text-3xl">{emoji}</p>
          </div>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

function App() {
  const [answer, setAnswer] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalEmoji, setModalEmoji] = useState('');
  const birthdayMessage = "Happy birthday to my favorite person and the best on the planet Earth! I made this silly project to tell you how much you mean to me. Just wanted to let you know that I will always be there for you, and I feel so lucky to have such a beautiful person in my life.  Hope this virtual birthday gift brings a smile to your face. Wishing you a year full of happiness, joy, and lots of chemistry 🧪. I can't wait to meet you in person in Aug. Love you to the max, bestie ❤️. Made with love by your annoying friend, MaZZen.";
  const handleSubmit = (event) => {
    event.preventDefault();
    if (answer.toLowerCase() === 'mazen') {
      setModalMessage(birthdayMessage);
      setModalEmoji('');
    } else {
      setModalMessage('Oh no! That is not correct. Now Mazen is heartbroken');
      setModalEmoji(' 💔  💔  💔  💔  💔  💔  💔 ');
    }
    setShowModal(true);
  };

  const handleInputChange = (event) => {
    setAnswer(event.target.value);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="bg-[#DC143C] w-full h-full absolute">
      <Modal show={showModal} message={modalMessage} emoji={modalEmoji} onClose={closeModal} />
      <div className="flex justify-center items-center h-screen">
        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit} netlify>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="bestFriend"
            >
              Who is Farah's best friend?
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="bestFriend"
              type="text"
              placeholder="Enter name"
              value={answer}
              onChange={handleInputChange}
            />
          </div>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default App;